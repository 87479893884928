import React from 'react';
import Button from 'react-bootstrap/Button';
import Navbar from 'react-bootstrap/Navbar';

import './App.css';

import buildInfo from './buildInfo';
import { displayDialog, displayErrorDialog } from './dialogs';
import { invokeCloudFunction } from './cloud';

// https://gist.github.com/juhaelee/b80ab497f74e7393b15439c17421d299
interface ocProps {

}

interface ocState {
  btnEnabled: boolean;
  btnText: string;
  gcec: string;
  hasCode: boolean;
}

class App extends React.Component<ocProps, ocState> {

  constructor(props: ocProps) {
    super(props);
    this.state = {
      gcec: '',
      hasCode: false,
      btnEnabled: true,
      btnText: "Push Button"
    };
    this.doPush = this.doPush.bind(this);
  }

  componentDidMount() {
    console.log('');
    console.log('Garage Controller: Opener Code');
    console.log('By John M. Wargo');
    console.log('Fumbly Diddle Software (https://fumblydiddle.com)');
    console.log(`Build Number: ${buildInfo.buildVersion}`);
    const buildDate = new Date(buildInfo.buildDate);
    console.log(`Build Date: ${buildDate.toString()}`);
    console.log('');

    var searchParams = new URLSearchParams(window.location.search);
    // get the gcec parameter
    var theParam = searchParams.get('gcec');
    console.log(`GCEC: ${theParam}`);
    // do we have a cgec? Then use it
    var gcec = theParam && theParam.length > 0 ? theParam : '';
    this.setState({
      gcec: gcec,
      hasCode: (gcec.length > 0)
    });
  }

  doPush() {
    console.log('doPush()');
    let functionData = {
      gcec: this.state.gcec
    };
    invokeCloudFunction('singleUsePushV1', functionData)
      .then(response => {
        if (response) {
          console.log('doPush: Received a response from the server');
          response.json().then(data => {
            if (data.result) {
              console.log(data.message);
              if (response.status === 200) {
                // Change the button text and disable it
                this.setState({ btnText: "Already Pushed", btnEnabled: false });
                displayDialog('Push Button', data.message);
              } else {
                displayErrorDialog(data.message);
              }
            } else {
              displayErrorDialog(data.message);
            }
          });
        }
      })
      .catch(err => {
        console.warn(err);
        console.dir(err);
        displayErrorDialog();
      });
  }

  render() {
    if (this.state.hasCode) {
      return (
        <div>
          <Navbar bg="dark">
            <Navbar.Brand>
              <img alt="Garage Controller Logo"
                src="garage-controller-logo-transparent-200.png"
                className="d-inline-block align-top"
              />
            </Navbar.Brand>
          </Navbar>
          <div className="body-container">
            <h2>Garage Door Opener</h2>
            <p>This single use URL you launched enables you to trigger the homeowner's garage door button{' '}
              <strong>once</strong>. Yes, only <strong>once</strong>.</p>
            <Button
              disabled={!this.state.btnEnabled}
              variant="primary"
              id="doPush"
              onClick={this.doPush}
            >{this.state.btnText}</Button>{' '}
          </div>
        </div>
      );
    } else {
      return (
        <div>
          <Navbar bg="dark">
            <Navbar.Brand>
              <img alt="Garage Controller Logo"
                src="garage-controller-logo-transparent-200.png"
                className="d-inline-block align-top"
              />
            </Navbar.Brand>
          </Navbar>
          <div className="body-container">
            <h2>Garage Door Opener</h2>
            <p>The system cannot process your request because, well, its invalid.</p>
          </div>
        </div>
      );
    }
  }
}

export default App;
