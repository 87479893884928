import { displayErrorDialog } from './dialogs';

const ENDPOINT_ROOT = 'https://us-central1-fumbly-garage-controller.cloudfunctions.net';

export async function invokeCloudFunction(functionName, data) {
    console.log(`callCloudFunction(${functionName})`);
    const theUrl = new URL(`${ENDPOINT_ROOT}/${functionName}`);
    try {
        // Setup fetch options
        // https://developer.mozilla.org/en-US/docs/Web/API/WindowOrWorkerGlobalScope/fetch
        const fetchOptions = {
            method: 'POST',
            mode: 'cors',
            cache: 'no-cache',
            credentials: 'same-origin',
            headers: { 'Content-Type': 'application/json' },
            redirect: 'follow',
            referrerPolicy: 'no-referrer',
            body: JSON.stringify(data)
        }
        return fetch(theUrl.href, fetchOptions);
    } catch (err) {
        console.warn('Fetch failure');        
        displayErrorDialog(err);
    }
}
