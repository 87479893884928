import Swal from 'sweetalert2'

export function displayDialog(title, message) {
    Swal.fire({
        title: title,
        text: message,
        icon: 'success',
        timer: 1000
    });
}

export function displayErrorDialog(theMessage) {
    const defaultMessage = 'We\'re sorry, but the system could not process your request at this time';
    var message = theMessage ? theMessage : defaultMessage;
    Swal.fire({
        title: 'System Error',
        text: message,
        icon: 'error',
        confirmButtonText: 'OK'
    });
}
